
// 引入mescroll的vue组件
import MescrollVue from "mescroll.js/mescroll.vue";
import { AntiShakeVo, antiShakeFn } from "../commons/Utils";
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["up-event", "down-event"],
  components: {
    MescrollVue       
  },
  setup(props, context) {
    // 下拉更新
    const vo: AntiShakeVo = {
      fn: () => {
        context.emit("down-event");
      },
      delay: 0,
    };
    const fn = antiShakeFn(vo);
    // --------------------------------------------------
    const mescrollUp = {
      callback: (page: any, mescroll: any) => {
        if (page.num === 1) {
          // 上拉加载 + 第一次初始化进入
          context.emit("up-event");
        } else {
          // 添加防抖功能
          fn();
        }
        mescroll.endSuccess(10);
      },
    };

    return {
      mescrollUp,
      dataList: [1, 2, 3, 4, 5, 1, 2],
    };
  },
});
