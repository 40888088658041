
import AxiosAdapter from '../request/AxiosAdapter'
import Pagination from '@/components/Pagination/index.vue'
import HeaderTit from '@/components/HeaderTit.vue'
import myScroll from '@/components/myScroll.vue'
import { defineComponent, ref, reactive, toRefs, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { getRecord } from '../commons/Utils'
import { useRouter } from 'vue-router'
import { ResponsePage } from '../commons/Types'
import { throwCustomFunction } from '../commons/ErrorHandle'
export default defineComponent({
  name: 'DocumentsList',
  components: {
    HeaderTit,
    myScroll,
    Pagination
  },
  data() {
    return {
      list: [
        {
          id: 1,
          name: '待签收文书',
          number: 0
        },
        {
          id: 2,
          name: '已签收文书',
          number: 0
        },
        {
          id: 3,
          name: '已过期文书',
          number: 0
        }
      ]
    }
  },
  setup() {
    onMounted(() => {
      getRecord('进入地址确认书列表页面', true)
    })

    const router = useRouter()
    const state = reactive({
      documntList: [],
      people: ''
    })
    const activeIndex = ref(1)
    const pageNum = ref(1)
    const pages = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const prames = reactive({
      status: activeIndex,
      pageNum: pageNum,
      pageSize: pageSize
    })

    const getList = async (tableValue: any) => {
      const response = await AxiosAdapter.get<any, ResponsePage<any>>({
        url: '/webElectronic/electronicList',
        data: prames
      })
      if (prames.status !== tableValue) {
        getList(prames.status)
        return
      }
      if (response.records && response.records.length > 0) {
        state.documntList = response.records
        // state.documntList = state.documntList.concat(response.records);
      }
      pages.value = response.pages
      total.value = Number(response.total)
    }

    const paginationFun = (item: any) => {
      pageNum.value = item.page
      pageSize.value = item.limit
      state.documntList = []
      getList(activeIndex.value)
    }

    // getList();

    const tabDocumentFun = (item: any) => {
      activeIndex.value = item.id
      pageNum.value = 1
      state.documntList = []
      getList(item.id)
    }

    //文书签收

    const documentDetailFun = (item: any) => {
      //electronicType=0简易送达  1标准送达    isRequiredIdentityPhoto(Boolean)是否上传身份证号
      //electronicType=4地址确认书 简易送达
      //electronicType=0简易送达  1标准送达    isRequiredIdentityPhoto(Boolean)是否上传身份证号
      if (item.id) {
        if (item.electronicType == 1 && item.isRequiredIdentityPhoto) {
          router.push({
            path: '/authentication',
            query: {
              platformCode: item.platformCode,
              id: item.id,
              institutionId: item.institutionId,
              caseId: item.caseId,
              courtName: item.courtName,
              caseNumberWord: item.caseNumberWord,
              receiverName: item.receiverName,
              userName: item.userName,
              agentName: item.agentName
            }
          })
          return
        }
        if (item.electronicType == 4) {
          if (item.platformCode == '120') {
            router.push({
              path: '/west-city-confirmation',
              query: {
                id: item.id,
                institutionId: item.institutionId,
                caseId: item.caseId,
                courtName: item.courtName,
                caseNumberWord: item.caseNumberWord,
                receiverName: item.receiverName,
                userName: item.userName,
                agentName: item.agentName
              }
            })
            return
          }
          router.push({
            path:
              item.institutionId == 340202 ? '/sign-in-agreement' : '/confirmation',
            query: {
              id: item.id,
              caseNumberWord: item.caseNumberWord,
              receiverName: item.receiverName,
              institutionId: item.institutionId,
              type: item.institutionId == 340202 ? 'confirmation' : null
            }
          })
          // router.push({
          //   path: "/confirmation",
          //   query: {
          //     id: item.id,
          //     caseNumberWord: item.caseNumberWord,
          //     receiverName: item.receiverName,
          //     institutionId: item.institutionId,
          //   },
          // });
          return
        }
        //标准送达 已上传身份证
        if (item.electronicType == 1 && !item.isRequiredIdentityPhoto) {
          if (item.platformCode == '120') {
            router.push({
              path: '/west-city-confirmation',
              query: {
                id: item.id,
                institutionId: item.institutionId,
                caseId: item.caseId,
                courtName: item.courtName,
                caseNumberWord: item.caseNumberWord,
                receiverName: item.receiverName,
                userName: item.userName
              }
            })
            return
          }

          router.push({
            path: '/confirmation',
            query: {
              id: item.id,
              caseNumberWord: item.caseNumberWord,
              receiverName: item.receiverName,
              institutionId: item.institutionId
            }
          })
          return
        }
        if (item.electronicType == 0) {
          //简易送达
          router.push({
            path: '/simple-signature',
            query: {
              id: item.id,
              caseNumberWord: item.caseNumberWord,
              receiverName: item.receiverName,
              institutionId: item.institutionId
            }
          })
        }
      } else {
        throwCustomFunction('您的操作有误,请联系业务人员,id')
      }

      //   activeIndex.value = item.id;
    }

    //查看文书详情

    const seeDetailFun = (item: any) => {
      if (activeIndex.value === 3) {
        throwCustomFunction('文书已过期,无法查看')
      }
      router.push({
        path: '/documents-detail',
        query: {
          id: item.id,
          caseNumberWord: item.caseNumberWord
        }
      })
    }

    const getListDownEvent = async () => {
      const response = await AxiosAdapter.get<any, ResponsePage<any>>({
        url: '/webElectronic/electronicList',
        data: prames
      })
      if (response.records && response.records.length > 0) {
        state.documntList = state.documntList.concat(response.records)
      }
      pages.value = response.pages
      total.value = Number(response.total)
    }
    const downEvent = () => {
      pageNum.value = pageNum.value + 1
      if (pageNum.value > pages.value) {
        return
      }
      getListDownEvent()
    }
    const upEvent = () => {
      pageNum.value = 1
      state.documntList = []
      getList(activeIndex.value)
    }

    return {
      activeIndex,
      tabDocumentFun,
      documentDetailFun,
      seeDetailFun,
      downEvent,
      upEvent,
      pageNum,
      getList,
      paginationFun,
      prames,
      pageSize,
      total,
      ...toRefs(state)
    }
  }
})
